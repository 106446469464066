// Avoid `console` errors in browsers that lack a console.
(function() {
    var method;
    var noop = function () {};
    var methods = [
        'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
        'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
        'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
        'timeStamp', 'trace', 'warn'
    ];
    var length = methods.length;
    var console = (window.console = window.console || {});

    while (length--) {
        method = methods[length];

        // Only stub undefined methods.
        if (!console[method]) {
            console[method] = noop;
        }
    }
}());

// Place any jQuery/helper plugins in here.


// @codekit-prepend "../../../../../../node_modules/jquery/dist/jquery.js" quiet;
// @codekit-prepend "../../../../../../node_modules/jquery-migrate/dist/jquery-migrate.js" quiet;

// @codekit-prepend "jquery.cycle2.js" quiet;
// @codekit-prepend "jquery.cycle2.carousel.js" quiet;
// @codekit-prepend "jquery.cycle2.center.js" quiet;
// @codekit-prepend "jquery.cycle2.swipe.js" quiet;
// @codekit-prepend "jquery.cycle2.autoheight.js" quiet;




// -------------------------------------------------------------
/*
$.noConflict();
jQuery( document ).ready(function($) {
*/

jQuery.noConflict();
(function($) {
	
	

// burger nav --------------------------------------------------------------------------------------------------------------
	
	//$('header').addClass('burger-menu-not-active');
	
	$(document).on('click', '.hamburger', function(){
		$('.hamburger').toggleClass('is-active');
		$('body').toggleClass('bodyfix');
		$('header nav').toggleClass('is-active');
				
		//$('.sub-menu').closest('li').removeClass('submenu-open').addClass('submenu-closed'); // reset always the menu
		
		console.log('click burger');
	});


	$(document).on('click', '.menu-item-has-children', function(){
		$(this).toggleClass('is-active-sub');
				
		//$('.sub-menu').closest('li').removeClass('submenu-open').addClass('submenu-closed'); // reset always the menu
		
		console.log('click burger');
	});
	
	
	$(document).bind( "mouseup touchend", function(event){
		
	  var nav_container = $('header'); // YOUR CONTAINER SELECTOR
	
	  if (!nav_container.is(event.target) // if the target of the click isn't the container...
	      && nav_container.has(event.target).length === 0)  {  // ... nor a descendant of the container
			 $('body').removeClass('bodyfix'); 
			 $('header .hamburger').removeClass('is-active');
			 $('header nav').removeClass('is-active');
			 		  
			 console.log('clicked outside');
	  }
	});

	
	// close the menu with ESC key
	$(document).on('keyup',function(evt) {
		if (evt.keyCode == 27) {
			
			// burger navigation
			$('body').removeClass('bodyfix'); 
			$('.hamburger').removeClass('is-active');
			$('header nav').removeClass('is-active');
						 		  
			 console.log('esc close menu');
		}
	});



	
	
	// -------------------------------------------------------------------------------------  
	// smoth scroll to top
	$('a[href^="#"]').on('click',function(e) {
	 e.preventDefault();
	 var target = this.hash;
	 var $target = $(target);
	 $('html, body').stop().animate({
	  'scrollTop': $target.offset().top
	 }, 900, 'swing', function () {
	  window.location.hash = target;
	 });
	});
	// ------------------------------------------------------------------------------------- 

}(jQuery));
